import React from 'react';

import Header from '../components/Header/Header';
import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';
import SEO from '../components/SEO/SEO';

const NotFoundPage = () => {
  return (
    <>
      <SEO pageName="404" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <HeroTheLegend title="Page not found" isNotFound />
      </div>
    </>
  );
};

export default NotFoundPage;
