import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  position: relative;
  height: calc(100vh - 66px);
  margin-top: 66px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #141f35;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    align-items: center;
  }
`;

export const BgImage = styled.div`
  @media (max-width: 1023px) {
    margin-right: -24px;
    margin-bottom: 40px;
    margin-left: -24px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1127px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
  }

  @media (min-width: 1024px) {
    height: 60vh;
  }
`;

export const Title = styled.h1`
  margin-bottom: 19px;
  color: #fff;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 639px) {
    font-size: 32px;
  }

  @media (max-width: 1023px) {
    text-align: center;
  }

  @media (min-width: 640px) {
    font-size: 40px;
  }

  @media (min-width: 768px) {
    max-width: 570px;
    font-size: 53px;
    line-height: 50px;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 600px;
  }

  @media (min-width: 1024px) {
    max-width: 489px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9dfd0;
`;

export const FormWrapper = styled.div`
  margin-top: 32px;
`;

export const FormDesc = styled.div`
  color: #fff;
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    text-align: center;
  }
`;

export const Desc = styled.p`
  margin-top: 24px;
  margin-bottom: 16px;
  color: #e9dfd0;
  font-size: 18px;
  line-height: 30px;
  opacity: 1;
`;

export const Signature = styled.img`
  @media (max-width: 639px) {
    max-width: 250px;
  }
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  height: 48px;
  margin-top: 40px;
  padding: 0 12px;
  color: #ce9b61;
  border: 1px solid #ce9b61;
  font-family: 'DharmaGothic';
  font-size: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;


export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: 0;
  width: 39px;
  height: 272px;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const HeadingImg = styled.img`
  width: 39px;
`;

export const HeadingText = styled.div`
  margin-top: 26px;
  padding-left: 17px;
  color: #fff;
  font-family: 'GreatWestern';
  font-size: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-left: 1px solid #fff;
  transform: rotate(90deg);
`;
